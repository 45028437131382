import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GoogleAnalyticsTrackingService } from './google-analytics-tracking.service';
import { NavigateAuthService } from '@qv-common/services/auth/navigate-auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AuthService } from 'quantuvis-angular-common/auth';
import { PermissionService } from '@qv-common/services/auth/permission.service';
import { NavigationUtils } from 'quantuvis-angular-common/window-utils';
import { TermsAndConditionsService } from '@qv-terms-and-conditions/services';
import { HttpStatusCode } from 'quantuvis-angular-common/api';
import { EnrollmentService } from '@qv-common/services';
import { Enrollment } from 'quantuvis-core-entities';
import { UserService } from '@qv-common/services/auth/user.service';
import { NavigationService } from 'quantuvis-angular-common/navigation';

@Injectable()
export class BootstrapService {
  constructor(
    private router: Router,
    private userService: UserService,
    private authService: AuthService,
    private permissionService: PermissionService,
    private enrollmentService: EnrollmentService,
    private navigateAuthService: NavigateAuthService,
    private termsAndConditionsService: TermsAndConditionsService,
    private googleAnalyticsTrackingService: GoogleAnalyticsTrackingService,
    private navigationService: NavigationService,
  ) {
    this.initHandlers();
  }

  public bootstrap(): Observable<boolean> {
    const sessionId = this.authService.getSessionIdFromCurrentUrl();
    this.authService.setClientToken(sessionId);

    return this.authService.initLoginProcess().pipe(switchMap((hasSecureInfo: boolean) =>
      hasSecureInfo ? this.bootstrapForAuthenticatedUser() : this.bootstrapForUnAuthenticatedUser()));
  }

  private bootstrapForAuthenticatedUser(): Observable<boolean> {
    return this.userService.loadUserData().pipe(
      switchMap(() => this.permissionService.onBootstrap()),
      switchMap(() => this.loadEnrollments()),
      switchMap(() => this.redirectIfPossible()),
      catchError((response: HttpErrorResponse) => this.handleBootstrapError(response))
    );
  }

  private bootstrapForUnAuthenticatedUser(): Observable<boolean> {
    const isPublicPage = this.navigationService.isPublicPage();

    if (!isPublicPage) {
      NavigationUtils.setDeepLinkToCurrentUrl();
      this.authService.authorize();
    }

    return of(isPublicPage);
  }

  private loadEnrollments(): Observable<Enrollment[] | null> {
    return this.userService.isCurrentUserQuantuvisAdmin() ? of(null) : this.enrollmentService.loadEnrollments();
  }

  private redirectIfPossible(): Observable<boolean> {
    const deepLink = NavigationUtils.getDeepLink();
    this.authService.removeRedundantParamsFromURL();

    if (deepLink) {
      NavigationUtils.clearDeepLink();
      this.router.navigateByUrl(deepLink);

      return of(true);
    } else {
      return this.navigateAuthService.checkRedirectStrategy(window.location.href);
    }
  }

  private handleBootstrapError(response: HttpErrorResponse): Observable<boolean> {
    if (response.status !== HttpStatusCode.UNAUTHORIZED) {
      this.authService.logout();
    }
    return of(this.navigationService.isPublicPage());
  }

  private initHandlers(): void {
    this.googleAnalyticsTrackingService.init();
    this.navigateAuthService.init();
    this.termsAndConditionsService.init();
  }
}
