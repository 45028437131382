import {
  ApplicationRef,
  DoBootstrap,
  ErrorHandler,
  NgModule,
  NgModuleFactoryLoader,
  SystemJsNgModuleLoader
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradeModule } from '@angular/upgrade/static';
import { ApiUrlPrefix, AuthenticationRouteUtils } from '@qv-common/enums';
import { filter } from 'rxjs/operators';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { Ng1WrapperModule } from '@qv-ng1-wrapper/ng1-wrapper.module';
import { HeaderModule } from '@qv-header/header.module';
import { CenturyDateAdapter } from '@qv-shared/components/date-picker/century-date-adapter.service';
import { UserModule } from '@qv-user/user.module';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ToastrModule } from 'ngx-toastr';
import { MARKET_BASKET_TEMPLATE_URL, MEDISPAN_DRUGS_WITH_PHARMA_URL } from 'market-basket/market-basket-common';
import { ComponentsModule } from '../../../webapp/app/components/components.module';
import { CommonModule } from '@qv-common/common.module';
import { BidModule } from '@qv-bid/bid.module';
import { constants } from '@qv-common/static';
import { SharedModule } from '@qv-shared/shared.module';
import { CompanyModule } from '@qv-company/company.module';
import { InsightsModule } from '@qv-insights/insights.module';
import { applicationVersionInterceptorProvider, NoneAccessInterceptor } from '@qv-common/handlers';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from '@qv-env/environment';
import { AppModuleA1 } from '../../../webapp/app/app.module.a1';
import { BootstrapService } from '@qv-bootstrap/services';
import { BootstrapModule } from '@qv-bootstrap/bootstrap.module';
import { RootComponent } from '@qv-bootstrap/components';
import { appConfig } from '@qv-common/configs';
import { AppRoutingModule } from './app.routing';
import { TermsAndConditionsModule } from '@qv-terms-and-conditions/terms-and-conditions.module';
import { DrugSearchModule } from '@qv-drug-search/drug-search.module';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ModalModule } from 'quantuvis-angular-common/modal';
import { errorHandlerFactory } from './factories';
import { AuthModule } from '../auth/auth.module';
import {
  FieldsDropdownOptionsEffects,
  FieldsDropdownOptionsKey,
  fieldsDropdownOptionsReducer
} from 'quantuvis-core-entities';
import { NAVIGATION_TOKEN, NavigationModule } from 'quantuvis-angular-common/navigation';

const prodModules = environment.production
  ? [NgxGoogleAnalyticsModule.forRoot(appConfig.trackingCode)]
  : [];

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DrugSearchModule,
    UpgradeModule,
    NgbModule,
    NgxWebstorageModule.forRoot({ prefix: 'QV', separator: '-', caseSensitive: true }),
    ComponentsModule,
    HttpClientModule,
    MatButtonModule,
    MatIconModule,
    ToastrModule.forRoot(appConfig.toastrConfig),
    Ng1WrapperModule,
    AppRoutingModule,
    CommonModule,
    NavigationModule,
    HeaderModule,
    BidModule,
    SharedModule,
    CompanyModule,
    UserModule,
    ModalModule,
    InsightsModule,
    BootstrapModule,
    AuthModule,
    TermsAndConditionsModule,
    StoreModule.forRoot({
      [FieldsDropdownOptionsKey]: fieldsDropdownOptionsReducer
    }, appConfig.storeConfig),
    EffectsModule.forRoot([
      FieldsDropdownOptionsEffects
    ]),
    StoreDevtoolsModule.instrument({ logOnly: !environment.production }),
    ...prodModules
  ],
  providers: [
    { provide: NAVIGATION_TOKEN, useValue: AuthenticationRouteUtils.getAuthenticationRoutesWithFullPath() },
    { provide: NgModuleFactoryLoader, useClass: SystemJsNgModuleLoader },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    { provide: DateAdapter, useClass: CenturyDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: appConfig.dateFormatConfig },
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: constants.APP.MAT_TOOLTIP_DEFAULTS },
    { provide: MARKET_BASKET_TEMPLATE_URL, useValue: ApiUrlPrefix.MARKET_BASKET_TEMPLATES },
    { provide: MEDISPAN_DRUGS_WITH_PHARMA_URL, useValue: ApiUrlPrefix.MEDISPAN_DRUGS_WITH_PHARMA_URL },
    applicationVersionInterceptorProvider,
    { provide: HTTP_INTERCEPTORS, useClass: NoneAccessInterceptor, multi: true },
    { provide: ErrorHandler, useFactory: errorHandlerFactory },
  ],
  bootstrap: []
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule) {}

  public ngDoBootstrap(appRef: ApplicationRef): void {
    this.upgrade.bootstrap(document.documentElement, [AppModuleA1.name], { strictDi: true });
    const bootstrapService: BootstrapService = this.upgrade.injector.get(BootstrapService);
    bootstrapService.bootstrap()
      .pipe(filter((canLoadApp: boolean) => canLoadApp))
      .subscribe(() => appRef.bootstrap(RootComponent, document.getElementById('qv-root')));
  }
}
