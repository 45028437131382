export const environment = {
  production: true,
  auth: {
    host: envProperties.auth.host,
    clientId: envProperties.auth.clientId,
  },
  sentry: {
    dsn: envProperties.sentry.dsn,
    enabled: envProperties.sentry.enabled,
    release: buildProperties.revision
  },
  envUrls: {
    ia: envProperties.envUrls.ia,
    sm: envProperties.envUrls.sm,
    rn: envProperties.envUrls.rn,
    cm: envProperties.envUrls.cm,
    sa: envProperties.envUrls.sa,
    iq: envProperties.envUrls.iq
  }
};
